.start-screen {
  &__heading {
    font-size: map-get($font-size, 'lg');
    font-weight: map-get($font-weight, 'light');
    line-height: map-get($line-height, 'large');
    margin-bottom: em(22px);
  }

  &__text {
    color: map-get($brand-grey, 'dark');
    font-size: map-get($font-size, 'sm');
    font-weight: map-get($font-weight, 'normal');
    line-height: map-get($line-height, 'large');
  }

  &__list {
    color: map-get($brand-grey, 'dark');
    font-size: map-get($font-size, 'sm');
    font-weight: map-get($font-weight, 'medium');
    line-height: map-get($line-height, 'large');
    margin: em(20px) 0;
    padding-inline-start: em(30px);
  }

  &__list span {
    left: em(4px);
    position: relative;
  }

  &__info-wrapper {
    background-color: map-get($brand-grey, 'lighter');
    display: flex;
    gap: em(4px);
    margin-bottom: em(28px);
    margin-top: em(16px);
    padding: em(12px);
  }

  &__info {
    color: map-get($brand-black, 'light');
    font-size: map-get($font-size, 'xsm');
    line-height: map-get($line-height, 'large');
  }

  &__info-icon {
    margin-top: em(-4px);
  }
}
