.footer {
  margin: em(24px 0);
  text-align: center;
  width: 100%;
}

.footer-text {
  color: rgb(var(--theme-color-grey-dark));
  font-size: map-get($font-size, 'xsm');
  font-weight: map-get($font-weight, 'light');
}

.footer-link {
  text-decoration: none;
}
