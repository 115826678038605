.file-input {
  align-items: center;
  background-color: map-get($brand-grey, 'translucent');
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: em(75px);
  justify-content: center;
  width: em(75px);

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__icon {
    width: em(37px);
  }

  &__text {
    color: rgb(var(--theme-color-info-state));
    cursor: pointer;
    padding-top: em(16px);
  }

  &__file-input {
    display: none;
  }
}
