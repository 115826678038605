@font-face {
  font-family: 'Scto Grotesk';
  font-style: normal;
  font-weight: map-get($font-weight, 'normal');
  src: url('../fonts/scto_grotesk_a_regular.woff') format('woff');
}

@font-face {
  font-family: 'Scto Grotesk';
  font-style: normal;
  font-weight: map-get($font-weight, 'medium');
  src: url('../fonts/scto_grotesk_a_medium.woff') format('woff');
}

@font-face {
  font-family: 'Scto Grotesk';
  font-style: normal;
  font-weight: map-get($font-weight, 'bold');
  src: url('../fonts/scto_grotesk_a_bold.woff') format('woff');
}

@font-face {
	font-family: MiloADAC;
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/MiloADACMob-Regular.ttf") format("truetype");
}

@font-face {
	font-family: MiloADAC;
	font-style: normal;
	font-weight: bold;
	src: url("../fonts/MiloADACMob-Bold.ttf") format("truetype");
}

@font-face {
	font-family: MiloADAC;
	font-style: italic;
	font-weight: normal;
	src: url("../fonts/MiloADACMob-Italic.ttf") format("truetype");
}

@font-face {
	font-family: MiloADAC;
	font-style: italic;
	font-weight: bold;
	src: url("../fonts/MiloADACMob-BoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: MiloSlabADAC;
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/MiloSlabADACMob-Regular.ttf") format("truetype");
}

@font-face {
	font-family: MiloSlabADAC;
	font-style: normal;
	font-weight: bold;
	src: url("../fonts/MiloSlabADACMob-Bold.ttf") format("truetype");
}

@font-face {
	font-family: MiloSlabADAC;
	font-style: italic;
	font-weight: normal;
	src: url("../fonts/MiloSlabADACMob-Italic.ttf") format("truetype");
}

@font-face {
	font-family: MiloSlabADAC;
	font-style: italic;
	font-weight: bold;
	src: url("../fonts/MiloSlabADACMob-BoldItalic.ttf") format("truetype");
}
