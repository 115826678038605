button {
  align-items: center;
  appearance: none;
  background: transparent;
  border: none;
  border: var(--theme-button-border-width) solid transparent;
  border-radius: var(--theme-button-border-radius);
  color: rgb(var(--theme-button-color));
  cursor: pointer;
  display: flex;
  font-family: var(--theme-font-family);
  font-size: map-get($font-size, 'sm');
  font-weight: bold;
  justify-content: center;
  letter-spacing: em(.25px);
  line-height: var(--theme-button-line-heigth);
  transition: opacity 0.2s;
}
