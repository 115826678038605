.logo {
  align-items: center;
  color: rgb(var(--theme-color-primary));
  display: flex;
  justify-content: center;
}

.logo--small {
  height: .7em
}

.logo--medium {
  height: 4em
}

.logo--big {
  height: 6em;
}

.logo--inline {
  display: inline-block;
  padding: 0 0.5rem;
  vertical-align: text-top;
}
