.question {
  &__text {
    font-size: map-get($font-size, 'lg');
    font-weight: map-get($font-weight, 'light');
    margin: em(26px) em(0);
  }

  &__submit-info-wrapper {
    display: flex;
    justify-content: center;
    margin-top: em(40px);
  }

  &__submit-info {
    color: rgb(var(--theme-color-grey-dark));
    font-size: map-get($font-size, 'xsm');
    margin: em(16px);
    text-align: center;
    width: 80%;
  }
}
