.header {
    gap: calc(3vh - #{em(16px)});
    min-height: em(128px);
    padding-top: calc(#{em(16px)} + 2vh);
    place-items: center center;
    width: 100%;
}

.header-text {
    color: rgb(var(--theme-color-stage-text));
    font-size: map-get($font-size, 'xl');
    font-weight: map-get($font-weight, 'normal');
    line-height: map-get($line-height, 'small');
    margin: em(10px 0 19.2px);
    text-align: center;
}
