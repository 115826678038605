html {
  height: 100%;
}

body {
  background-color: rgb(var(--theme-color-stage-background));
  color: rgb(var(--theme-font-color));
  font-family: var(--theme-font-family);
  height: inherit;
  line-height: map-get($line-height, 'base');
  margin: auto;
}

h1, h2 {
  font-family: var(--theme-header-font-family);
  font-weight: bold;
}
