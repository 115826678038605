.progress-bar {
  display: flex;
  gap: em(4px);
  height: em(4px);

  &__fragment {
    background-color: rgb(var(--theme-color-grey-light));
    border-radius: var(--theme-general-border-radius);
    height: 100%;
  }

  &__fragment--active {
    animation: fade-in .2s ease;
    background-color: rgb(var(--theme-color-info-state));
  }
}

@keyframes fade-in {
  0% {
    background-color: rgb(var(--theme-color-grey-light));
    opacity: 0.23;
  }
}

