.authentication-provider-wrapper--loading {
  display: grid;
  height: 100%;
  inset: auto 0;
  place-content: center center;
  place-items: center;
  position: absolute
}

.authentication-provider-wrapper__text {
  padding: em(20px);
}

.authentication-provider-wrapper__error {
  display: grid;
  gap: em(20px);
  height: 100%;
  inset: auto 0;
  place-content: center center;
  place-items: center;
  position: absolute;
}
