.end-screen{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: map-get($line-height, 'large');
  min-height: em(300px);

  &__heading {
    font-size: map-get($font-size, 'lg');
    font-weight: map-get($font-weight, 'light');
    margin: em(22px);
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__text {
    color: map-get($brand-grey, 'dark');
    font-size: map-get($font-size, 'sm');
    font-weight: map-get($font-weight, 'normal');
    line-height: map-get($line-height, 'large');
    text-align: center;
  }
}
