.loader {
  animation: rotate 4s linear infinite;
  border: em(3px) solid map-get($brand-grey, 'translucent');
  border-radius: 50%;
  border-top: em(3px) solid rgb(var(--theme-color-info-state));
  height: em(16px);
  width: em(16px);

  &--big {
    height: em(48px);
    width: em(48px);
  }

  &--grey {
    border-top: em(4px) solid map-get($brand-grey, 'primary');
  }

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
