.file-entry {
  display: inline-flex;
  gap: em(8px);
  margin: em(8px 0);
  width: 100%;

  &__element {
    align-items: center;
    background-color: rgb(var(--theme-color-grey-light));
    border-radius: var(--theme-general-border-radius);
    display: flex;
    font-size: map-get($font-size, 'sm');
    gap: em(8px);
    height: em(40px);
    justify-content: flex-start;
    padding: em(8px 16px);
    transition: background-color .5s;
    width: 100%;
  }

  &--uploading {
    background-color: map-get($brand-white, 'primary');
    border: em(1px) solid rgb(var(--theme-color-grey-dark));
    color: rgb(var(--theme-color-grey-dark));
  }

  &--errored {
    background-color: rgb(var(--theme-color-error-state) / 25%);
  }

  &__error {
    font-size: map-get($font-size, 'xsm');
  }

  &__retry {
    color: rgb(var(--theme-color-info-state));
    cursor: pointer;
  }

  &__delete-button {
    background-color: rgb(var(--theme-color-grey-light));
    color: transparent;
    min-height: em(54px);
    transition: background-color .5s;
    width: em(54px);

    &:disabled {
      color: transparent;
      opacity: 1;
      pointer-events: none;
    }

    &:hover {
      background-color: rgb(var(--theme-color-grey-light));
      color: transparent;
    }
  }
}
