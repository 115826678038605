.page {
  align-items: center;
  -webkit-box-pack: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-box {
  background-color: map-get($brand-white, 'primary');;
  border-radius: var(--theme-general-border-radius);
  box-shadow: map-get($brand-grey, 'translucent') 0 8px 16px 0;
  display: block;
  padding: em(30px) em(40px);
  width: em(512px);
  word-wrap: break-word;

  @media screen and (width <= 567px) {
    min-width: 80%;
  }
}
