.error-box {
  align-items: center;
  background-color: rgb(var(--theme-color-error-state) / 25%);
  border-radius: var(--theme-general-border-radius);
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  font-size: map-get($font-size, 'xsm');
  gap: em(8px);
  justify-content: flex-start;
  margin: em(16px) 0;
  padding: em(12px);
  position: relative;
  width: 100%;
}

