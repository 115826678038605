.button {
  min-height: em(40px);
  padding: em(8px 24px 8px 24px);

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &--primary {
    background-color: rgb(var(--theme-color-primary));

    &:active {
      background-color: rgb(var(--theme-color-active-primary));
    }
  }

  &--secondary {
    background-color: var(--color-brand-secondary);
    border-color: var(--color-grey-light);

    &:active {
      background-color: var(--color-interaction-secondary-active);
    }
  }

  &:hover {
    background-color: rgb(var(--theme-button-hover-background-color));
    border-color: rgb(var(--theme-button-hover-border-color));
    color: rgb(var(--theme-button-hover-color));
  }

  &:disabled {
    background-color: rgb(var(--theme-color-grey-medium));
    box-shadow: none;
    color: map-get($brand-white, 'primary');
    opacity: 0.9;

    &:hover {
      border-color: transparent;
    }
  }

  &--loading {
    animation: loading 1.2s ease-in-out infinite;
    background-image: linear-gradient(90deg, rgb(var(--theme-color-grey-medium)) 30%, rgb(var(--theme-color-grey-light)) 50%, rgb(var(--theme-color-grey-medium)) 70%);
    background-size: 400%;
    color: transparent;
  }

  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }
}
