@use "sass:meta";

:root {
  // colors
  --theme-color-stage-background: 247 247 248; // map-get($brand-grey, 'lighter')
  --theme-color-primary: 255 102 51; // map-get($brand-orange, 'primary')
  --theme-color-secondary: 96 137 245; // map-get($brand-blue, 'light')
  --theme-color-error-state: 220 24 41; // map-get($brand-red, 'primary')
  --theme-color-success-state: 0 128 0; // map-get($brand-green, 'primary')
  --theme-color-info-state: 22 82 240; // map-get($brand-blue, 'primary')
  --theme-color-grey-dark: 65 77 93; // map-get($brand-grey, 'dark')
  --theme-color-grey-medium: 214 216 220; // map-get($brand-grey, 'light')
  --theme-color-grey-light: 247 247 248; // map-get($brand-grey, 'lighter')
  --theme-color-active-primary: 255 102 51; // map-get($brand-orange, 'primary')
  --theme-color-active-secondary:255 255 255; // map-get($brand-white, 'primary')

  // typography
  --theme-font-family: 'Scto Grotesk', sans-serif;
  --theme-header-font-family: 'Scto Grotesk', sans-serif;
  --theme-font-color: 15 25 38;
  
  // button
  --theme-button-border-radius: #{meta.inspect(map-get($border-radius, 'standard'))};
  --theme-button-border-width: 2px;
  --theme-button-color: 255 255 255;
  --theme-button-hover-background-color: 255 255 255;
  --theme-button-hover-border-color: 255 102 51;
  --theme-button-hover-color: 15 25 38;

  // general
  --theme-general-border-radius: #{meta.inspect(map-get($border-radius, 'standard'))};
}
