.input-amount__wrapper{
  position: relative;
}

.input-amount__unit {
  align-items: center;
  border-left: 1px solid rgb(var(--theme-color-grey-dark));
  box-sizing: border-box;
  color: rgb(var(--theme-font-color));
  display: inline-flex;
  font-size: em(14px);
  height: 100%;
  justify-content: center;
  letter-spacing: em(0.4px);
  min-width: em(48px);
  padding: em(10px 0 9.6px);
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
}

.input:hover + .input-amount__unit {
  border-color: rgb(var(--theme-font-color));
  color: rgb(var(--theme-font-color));
}

.input:focus + .input-amount__unit {
  border-color: rgb(var(--theme-color-info-state));
  color: rgb(var(--theme-color-info-state));
}
