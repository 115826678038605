.input {
  border: em(1px) solid rgb(var(--theme-font-color));
  border-radius: var(--theme-general-border-radius);
  box-sizing: border-box;
  color: rgb(var(--theme-font-color));
  line-height: var(--theme-line-heigth);
  min-height: em(52px);
  outline: none;
  padding: em(10px 12px);
  position: relative;
  transition: all 0.2s;
  width: 100%;

  &:hover {
    border-color: rgb(var(--theme-color-primary));
  }

  &:focus {
    border-color: rgb(var(--theme-color-active-primary));
  }

  &::placeholder {
    color: rgb(var(--theme-color-grey-medium));
  }

  &--errored {
    border-color: rgb(var(--theme-color-error-state));
  }

  &--errored:hover {
    border-color: rgb(var(--theme-color-primary));
  }
}
