.icon {
  svg {
    fill: currentcolor;
  }

  &--small {
    height: em(16px)
  }

  &--medium {
    height: em(24px);
  }

  &--big {
    height: em(60px);
  }

  &--red {
    color: rgb(var(--theme-color-error-state));
  }

  &--grey {
    color: rgb(var(--theme-color-grey-dark));
  }

  &--green {
    color: rgb(var(--theme-color-success-state));
  }
 }
